import React, { useState, useEffect, useCallback, useRef } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { get, getAndConcat } from '../../reducers/actions/user.js'
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SearchBanner from '../../components/searchBanner'
import ListItem from '../../components/listItem'

const Provider = () => {
  const users = useSelector(state => state.userReducer.getUsers)
  const dispatch = useDispatch()
  const [isFirstRender, setIsFirstRender] = useState(true)

  const queryParams = {
    page: 1,
    limit: 40,
    provider: true
  }

  useEffect(() => {
    if(isFirstRender) {
      get(dispatch)(queryParams)
      setIsFirstRender(false)
    }
  })

  const loadDocuments = entry => {
    if (entry.intersectionRatio > 0) {
      if(
        !users.loading &&
        users.response &&
        users.response.totalPages > users.response.page
      ) {
        getAndConcat(dispatch)({...queryParams, page: users.response.page+1})
        }
    }
  }
 
  const scrollObserver = useCallback(node => {
    const observer =  new IntersectionObserver(entries => entries.forEach(loadDocuments))
    observer.observe(node)
  })
  const boundaryRef = useRef(null);
  
  useEffect(() => {
    if (boundaryRef.current) {
      scrollObserver(boundaryRef.current)
    }
  }, [users, boundaryRef])

  const list = users.response ? users.response.docs.map(user => ({
    title: user.provider.name,
    url: `/provider?id=${user._id}`,
    text: user.provider.description,
    highlights: {
      'Trabajos Realizados': user.provider.nProjects,
      'Calificaciones': user.score,
    },
    actions: []
  })): []

  const {page, limit} = users.response ? users.response : queryParams

  return (
    <Layout>
      <SEO title="Proveedor" />
      <h1>Proveedor</h1>
      <SearchBanner/>
      <div style={{display: 'flex', marginTop: '2.5rem', flexDirection: 'column'}}>
        {list.map((user, index) =>
          <div key={index} style={{margin: '0 0 1.5rem 0'}}>
            <ListItem {...user}/>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Provider
